export {showMessage, hideMessage, beforeAlert, beforeMessage, prependMessage}

// komunikat ogólny
function showMessage(message, classM=null, obj=null) {
	if(classM === undefined || classM === null || classM === ''){
		classM = 'info';
	}
	var l = message.length,
		adminPanel = $('body').prop('id') !== undefined && $('body').prop('id').length && $('body').prop('id') === 'adminPage',
		adminSidebar = (adminPanel ? $('body').find('#sidebar').length : false),
		cls = adminPanel && !adminSidebar ? 
			'alert alert-' + classM + ' alert-dismissible fade show len'+l :
			'alert alert-' + classM + ' alert-dismissible fade show len'+l+' mb-2',
		clx = ('.alert.alert-' + classM + '.alert-dismissible.fade.show.len'+l),
		clt = '<span aria-hidden="true">&times;</span>',
		clo = '<button type="button" class="close bg-trans" data-dismiss="alert" aria-label="Close">' + clt + '</button>',
		sty = adminPanel && !adminSidebar ? 'style="font-size: smaller; line-height: 1.2; margin-bottom: 0;"' : '',
		msg = '<div class="'+cls+'" role="alert" '+sty+'>' + message + clo + '</div>',
		tMsg = 10000;

	if(!adminPanel && (obj === undefined || obj === null || !obj.length || (typeof obj === 'string' && obj.includes('messages')) || (obj instanceof jQuery && obj.hasClass('messages')))) {
		// zwykłe komunikaty pod header
		$('#site-header > .messages').prepend(msg);
	}else{
		if(adminSidebar){
			// w panelu admina, na stronie settings (z sidebarem)
			$('body').find('#sidebar').prepend(msg);
		} else if (adminPanel && ((typeof obj === 'string') || (obj instanceof jQuery))) {
			// w panelu admina, ponad konkretnym obiektem, np. w oknie modalnym
			var $obj = (typeof obj === 'string') ? $('body').find(obj) : obj,
				$objMsg = $obj.closest('.form-group') !== undefined ? $obj.closest('.form-group') : $obj;
			tMsg = 20000;
			$objMsg.before(msg);
			$objMsg.siblings('.alert').css('margin-bottom', '6px');
		} else if (adminPanel) {
			// w panelu admina nad nagłówkiem
			var $container = $('body').find('.admin-messages');
			tMsg = 20000;
			$container.prepend(msg);
			$container.find('.alert').css('margin', '3px 5%');
		}else{
			// pozostałę przypadki, czyli obj nie jest nullem, nie zawiera też klasy messages
			var $obj = (typeof obj === 'string') ? $('body').find(obj) : obj;
			if($obj.parent().hasClass('row')){
				msg = '<div class="' + cls + ' col-12" role="alert">' + message + clo + '</div>';
				console.log('c',message);
			}
			// jeśli podajemy beforeMessage w skrypcie wywołującym komunikat, 
			// zwykle konieczne w formularzu dla danego pola 
			// lub w modalu, jeśli go nie zamykamy
			$obj.before(msg);
		}
	}
	setTimeout(function(){
		$('body').find(clx).fadeOut(700, function() {
			$(this).remove();
		});
	}, tMsg);
}

// usuwanie komikatu
function hideMessage($btn){
	var $p = $btn.parent();
	$p.fadeOut(700);
	setTimeout(function(){
		$p.remove();
	}, 800);
}

// komunikat dla konkretnych pól formularza
function beforeAlert($object, msg, type='danger', id=''){
	var $group = $object.closest('.form-group');
	if($group === undefined){
		var $group = $object;
	}
	console.log('$group', $group);
	var $alert = $group.find('.alert');
	if($alert !== undefined){
		$alert.remove();
	}
	var idx = id.length ? 'id="' + id + '"' : '';
	$group.prepend('<div ' + idx + ' class="alert alert-' + type + ' alert-dismissible fade show" role="alert">' + msg + ' <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>');
	if(id.length){
		setTimeout(function(){
			$('body').find(id).fadeOut(700, function() {
				$(this).remove();
			});
		}, 10000);
	}
}

// komunikat dla konkretnych pól formularza
function beforeMessage($object, msg, type='danger', id=''){
	var idx = id.length ? 'id="' + id + '"' : '';
	if($object !== undefined && msg !== undefined && msg.length){
		$object.before('<div ' + idx + ' class="alert alert-' + type + ' alert-dismissible fade show" role="alert">' + msg + ' <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>');
	}
	if(id.length){
		setTimeout(function(){
			$('body').find(id).fadeOut(700, function() {
				$(this).remove();
			});
		}, 10000);
	}
}

// komunikat dla konkretnych pól formularza
function prependMessage($object, msg, type='danger', id=''){
	var idx = id.length ? 'id="' + id + '"' : '';
	if($object !== undefined && msg !== undefined && msg.length){
		$object.prepend('<div ' + idx + ' class="alert alert-' + type + ' alert-dismissible fade show" role="alert">' + msg + ' <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>');
	}
	if(id.length){
		setTimeout(function(){
			$('body').find(id).fadeOut(700, function() {
				$(this).remove();
			});
		}, 10000);
	}
}