export {nl2br, array_filter, strToArray, formatBytes, scrollTo, rgb2hex}

$(document).ready(function () {
	// wyskakująca pomoc:
	// <a href="javascript:void(0)" class="btn-help" data-toggle="popover" title="Tytuł" data-content="Treść" [data-placement="right"]>
	//   <i class="fas fa-question-circle"></i>
	// </a>
	$('[data-toggle="popover"]').popover();
});

/**
 * End line to <br>
 *
 * @param {String} str
 * @returns {String}
 */
function nl2br(str) {
	return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br>' + '$2');
}

/**
 * Clear array
 * eg use:
 * var $pn = pn.split('/').filter(array_filter);
 *
 * @param {Array} $array
 * @returns {Array}
 */
function array_filter($array) {
	return $array !== '';
}

/**
 * strToArray
 * eg use:
 * var $elements = strToArray('|', 'any|string');
 *
 * @param {String} divider
 * @param {String} str
 * @returns {Object}
 */
function strToArray(divider, str) {
	return str.split(divider).filter(array_filter);
}

function formatBytes(bytes) {
	var marker = 1024; // Change to 1000 if required
	var decimal = 3; // Change as required
	var kiloBytes = marker; // One Kilobyte is 1024 bytes
	var megaBytes = marker * marker; // One MB is 1024 KB
	var gigaBytes = marker * marker * marker; // One GB is 1024 MB

	// return bytes if less than a KB
	if (bytes < kiloBytes)
		return bytes + " Bytes";
	// return KB if less than a MB
	else if (bytes < megaBytes)
		return(bytes / kiloBytes).toFixed(decimal) + " KB";
	// return MB if less than a GB
	else if (bytes < gigaBytes)
		return(bytes / megaBytes).toFixed(decimal) + " MB";
	// return GB if less than a TB
	else
		return(bytes / gigaBytes).toFixed(decimal) + " GB";
}


function scrollTo($selector, miliSeconds = 700) {
	if ($selector instanceof jQuery) {
		var $ob = $selector;
	} else {
		var $ob = $('body').find($selector);
	}
	if ($ob !== undefined && $ob instanceof jQuery && $ob.length) {
		$('html,body').animate({scrollTop: $ob.offset().top - 85}, miliSeconds);
	}
	return false;
}

function rgb2hex(rgb) {
	let sep = rgb.indexOf(",") > -1 ? "," : " ",
		rgbArray = rgb.substr(4).split(")")[0].split(sep),
		r = (+rgbArray[0]).toString(16),
		g = (+rgbArray[1]).toString(16),
		b = (+rgbArray[2]).toString(16);

	if (r.length === 1) r = "0" + r;
	if (g.length === 1) g = "0" + g;
	if (b.length === 1) b = "0" + b;

	return "#" + r + g + b;
}