export {readCookie, createCookie, deleteCookie, checkCookie, searchToCookieName}
import {dataFromForm} from './livedata';

function readCookie(nameCookie) {
	var nameEQ = nameCookie + "=";
	var ca = document.cookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1, c.length);
		}
		if (c.indexOf(nameEQ) === 0) {
			var value = c.substring(nameEQ.length, c.length);
			return value;
		}
	}
	
	return '';
}

function createCookie(nameCookie, valueCookie, expiresDays=1, path='') {
	var d = new Date();
	d.setTime(d.getTime() + (expiresDays * 24 * 60 * 60 * 1000));
	var expires = "expires=" + d.toUTCString();
	var domain = readCookie('cookie_domain');
	var newCookie = nameCookie + '=' + valueCookie + '; ' + expires + '; path=/' + path + "; domain=" + domain + "; samesite=lax";
	document.cookie = newCookie;
	console.log('Create Cookie "' + nameCookie + '"', newCookie);
}

function deleteCookie(nameCookie) {
	createCookie(nameCookie, "", -1);
}

function checkCookie(nameCookie) {
  var value = readCookie(nameCookie);
  if (value !== '') {
    return true;
  }
  
  return false;
}

function searchToCookieName ($searchForm, cookieName=null){
	var data = dataFromForm($searchForm);
	if(cookieName && cookieName.length){
		createCookie(cookieName, data, 1);
	}
}