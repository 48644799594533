export {liveLoadData, filterLoad, dataFromForm, submitData, getData, submitDataFile, emailTest}
import {showMessage} from './message';
import {strToArray} from './helpers';
import {createCookie, checkCookie, readCookie} from './cookie';
import {validateEmail} from './test';
import {trans, t} from './dictionary';

// for rendering html result
function liveLoadData($formObj, $fillObj, cookieName='') {
//	console.log('livedata/liveLoadData/formObject', $formObj);
	var action = $formObj.attr('action'),
		actionType = $formObj.data('actionType') === 'submit' ? 'formSubmit' : 'dataSubmit';
	console.log('liveLoadData::action', action);
	console.log('liveLoadData::actionType', actionType);
	switch(actionType){
		case 'formSubmit' : 
		case 'submit' : 
			$formObj.submit(); 
			break;
		default : 
			if(cookieName.length){
				var $from = $formObj.find('input[name=from]');
				if($from !== undefined && $from.length){
					console.log('$from',$from.val());
					setTimeout(function(){
						$from.val('other');
						searchToCookieName($formObj, cookieName);
						console.log('$from',$from.val());
					}, 3000	);
				}
			}
			submitData(dataFromForm($formObj), action, $fillObj, 'html');
	}
}

function searchToCookieName ($form, cookieName){
	var data = dataFromForm($form);
	createCookie(cookieName, data, 1);
}

function filterLoad($formObj, $fillObj) {
	var action = $fillObj.data('action');
	console.log('filterLoad::action', action);
	submitData(dataFromForm($formObj), action, $fillObj);
}

function dataFromForm($formObj, asJson = 1) {
	if($formObj === undefined || !$formObj.length){
		return false;
	}
	var type = $formObj.prop('nodeName');
	if (type !== 'FORM') {
		var formData = $formObj.closest('form').serializeArray();
	} else {
		var formData = $formObj.serializeArray();
	}
//	console.log('dataFromForm::formData', formData);

	var data = {};
	if (jQuery.isArray(formData)) {
		$(formData).each(function (index, obj) {
			var nameRaw = obj.name,
			    name = nameRaw.replace('[]', '[' + index + ']');
			data[name] = obj.value;
		});
	}
	return asJson ? JSON.stringify(data) : data;
}

// the same as axios for vue, but only for data - without sending files
function submitData(data, action, fill = null, typeFill = 'html', $formFind = null, $fillFind = null, $beforeMessage=null) {
console.log('submitData POST', {
		'data': data, 
		'action': action, 
		'fill': fill, 
		typeFill: typeFill,
		'$formFind': $formFind,
		'$fillFind': $fillFind,
		'$beforeMessage': $beforeMessage
	});
	$.post(action, data, function (response) {
		// return
		try {
			// response is json
			var o = jQuery.parseJSON(response);
//			console.log('RESPONSE JSON', o);
		} catch (error) {
			var o = response;
//			console.log('RESPONSE NoJSON', o);
		}
		
//		console.log('fill', fill);
		if (fill === 'redirect'){ 
			console.log('o.success', o.success);
			console.log('o.url', o.url);
			if(o.success !== undefined && o.success && o.url !== undefined){
				window.location.href = o.url;
			}
		}

		// zwracany przez kontroler php content, komunikaty i opcjonalnie fill oraz typeFill
		var content = '';
		var f = fill;
		var tf = typeFill === null ? 'html' : typeFill;
		if (typeof o === 'string' || typeof o === 'number') {
			content = o; // string
		} else {
			// obiekt musi zawierać klucz o nazwie content z zawartością
			if (o.hasOwnProperty('content')) {
				content = o.content;
			}
			if (o.hasOwnProperty('fill')) {
				f = o.fill;
			}
			if (o.hasOwnProperty('typeFill')) {
				tf = o.typeFill;
			}
			////////// messages //////////
			// gdzie wyświetlić
			var messageTo = '';
			if($beforeMessage !== null){
				if($beforeMessage instanceof jQuery){
					messageTo = $beforeMessage;
				}else{
					messageTo = $($beforeMessage);
				}
			}else if($fillFind !== null){
				messageTo = $fillFind;
			}else{
				messageTo = null;
			}
			// pojedynczy komunikat
			if (o.hasOwnProperty('message') && o.message) {
				showMessage(o.message, o.status, messageTo);
			}
			// kilka komunikatów (w array'u)
			if (o.hasOwnProperty('messages') && $.isArray(o.messages) && o.messages) {
				$.each(o.messages, function(i, msg){
					showMessage(msg.message, msg.status, messageTo);
				});
			}
			// stare
			if (o.hasOwnProperty('alert') && o.alert) {
				showMessage(o.alert, 'alert', messageTo);
			}
			// stare
			if (o.hasOwnProperty('error') && o.error) {
				showMessage(o.error, 'error', messageTo);
			}
		}
		// dodanie odpowiedzi do obiektu
		var $ob = {};
//		console.log('f',f);
		if (f !== null) {
			if (f instanceof jQuery) {
				$ob = f;
			} else {
				$ob = $(f);
			}
			switch(tf){
				case 'val':
				case 'value':
					$ob.val(content);
					break;
				case 'check':
				case 'checked':
					$ob.attr('checked', content);
					break;
				case 'select':
				case 'selected':
					$ob.attr('selected', content);
					break;
				case 'number':
				case 'text':
				case 'txt' :
					$ob.text(content);
					console.log('$ob.text(content):', content.replace(/(?:\r\n|\r|\n|\t)/g,'').slice(0,50));
					break;
				case 'html':
					$ob.html(content);
					console.log('$ob.html(content):', content.replace(/(?:\r\n|\r|\n|\t)/g,'').slice(0,100));
					break;
				case 'show':
					$ob.show(700);
					break;
				case 'hide':
					$ob.hide(700);
					break;
				case null :
					console.log('typeFill is null');
				default :
					showMessage('Data "typeFill" ' + tf + ' is unknown type!', 'danger');
			}
		} else {
			return content;
		}
		if ($formFind !== null && $fillFind !== null) {
			liveLoadData($formFind, $fillFind);
		}
	});
}

function submitDataFile($form, action, fill = null, typeFill = 'html', $beforeMessage=null) {
	console.log('submitData FILE', {
		'$form': $form, 
		action: action, 
		fill: fill, 
		typeFill: typeFill,
		'$beforeMessage': $beforeMessage
	});

	$.ajax({
		url: action,
		data: new FormData($form),
		type: "post",
		contentType: false,
		processData: false,
		cache: false,
		dataType: "json",
		error: function (err) {
			console.error(err);
		},
		success: function (data) {
//			console.log(data);
			try {
				// response is json
				var o = jQuery.parseJSON(data);
				console.log('RESPONSE JSON', o);
			} catch (error) {
				var o = data;
				console.log('RESPONSE NoJSON', o);
			}
			// zwracany przez kontroler php content, komunikaty i opcjonalnie fill oraz typeFill
			var content = '';
			var f = fill;
			var tf = typeFill;
			if (typeof o === 'string' || typeof o === 'number') {
				content = o; // string
			} else {
				// obiekt musi zawierać klucz o nazwie content z zawartością
				if (o.hasOwnProperty('content')) {
					content = o.content;
				}
				if (o.hasOwnProperty('fill')) {
					f = o.fill;
				}
				if (o.hasOwnProperty('typeFill')) {
					tf = o.typeFill;
				}
				// messages
				var messageTo = '';
				if($beforeMessage !== null){
					if($beforeMessage instanceof jQuery){
						messageTo = $beforeMessage;
					}else{
						messageTo = $($beforeMessage);
					}
				}else{
					var  mTo = $('body').find('.container-content');
					if(mTo !== undefined){
						messageTo = mTo;
					}
				}
				if (o.hasOwnProperty('message') && o.message) {
					if (o.hasOwnProperty('status')){
						showMessage(o.message, o.status, messageTo);
					}else{
						showMessage(o.message, 'info', messageTo);
					}
				}
				if (o.hasOwnProperty('messages') && $.isArray(o.messages) && o.messages) {
					$.each(o.messages, function(i, msg){
						showMessage(msg.message, msg.status, messageTo);
					});
				}
				if (o.hasOwnProperty('alert') && o.alert) {
					showMessage(o.alert, 'alert', messageTo);
				}
				if (o.hasOwnProperty('error') && o.error) {
					showMessage(o.error, 'error', messageTo);
				}
			}
			// dodanie odpowiedzi do obiektu
			var $ob = {};
			if (f !== null) {
				if (f instanceof jQuery) {
					$ob = f;
				} else {
					$ob = $(f);
				}
				if (tf === 'val' || tf === 'value') {
					$ob.val(content);
				} else if (tf === 'checked') {
					$ob.attr('checked', content);
				} else if (tf === 'number' || tf === 'text' || tf === 'txt') {
					$ob.text(content);
					console.log(content.replace(/(?:\r\n|\r|\n|\t)/g,'').slice(0,50));
				} else if (tf === 'html') {
					$ob.html(content);
					console.log(content.replace(/(?:\r\n|\r|\n|\t)/g,'').slice(0,100));
				} else if (tf === 'show') {
					$ob.show(700);
				} else if (tf === 'hide') {
					$ob.hide(700);
				} else {
					showMessage('Data "typeFill" ' + tf + ' is unknown type!', 'error', 'main');
				}
			} else {
				return content;
			}
		},
		complete: function () {
			console.log("Request upload finished.");
		}
	});
}


// the same as axios for vue, but only for data - without sending files
function getData(action, fill = null, typeFill = 'html', $formFind = null, $fillFind = null, $beforeMessage=null) {
	console.log('submitData GET', {
		action: action, 
		fill: fill, 
		typeFill: typeFill,
		'$formFind': $formFind,
		'$fillFind': $fillFind,
		'$beforeMessage': $beforeMessage
	});
	$.get(action, function (response) {
		// return
		try {
			// response is json
			var o = jQuery.parseJSON(response);
			console.log('RESPONSE JSON', o);
			if (o === true && $formFind !== null && $fillFind !== null) {
				// jeśli odpowiedź dla get jest dokładnie "true" i podano formularz + miejsce zwrotu dla niego
				liveLoadData($formFind, $fillFind);
				return false;
			}
		} catch (error) {
			var o = response;
			console.log('RESPONSE NoJSON', o);
		}
		// zwracany przez kontroler php content, komunikaty i opcjonalnie fill oraz typeFill
		var content = '';
		var f = fill;
		var tf = typeFill;
		if (typeof o === 'string' || typeof o === 'number') {
			content = o; // string
		} else {
			// obiekt musi zawierać klucz o nazwie content z zawartością
			if (o.hasOwnProperty('content')) {
				content = o.content;
			}
			if (o.hasOwnProperty('fill')) {
				f = o.fill;
			}
			if (o.hasOwnProperty('typeFill')) {
				tf = o.typeFill;
			}
			// messages
			var messageTo = '';
			if($beforeMessage !== null){
				if($beforeMessage instanceof jQuery){
					messageTo = $beforeMessage;
				}else{
					messageTo = $($beforeMessage);
				}
//			}else if($fillFind !== null){
//				messageTo = $fillFind;
			}
			if (o.hasOwnProperty('message') && o.message) {
				if(messageTo.length){
					showMessage(o.message, o.hasOwnProperty('status') ? o.status : 'info', messageTo);
				}else{
					showMessage(o.message, o.hasOwnProperty('status') ? o.status : 'info');
				}
			}
			if (o.hasOwnProperty('messages') && $.isArray(o.messages) && o.messages) {
				$.each(o.messages, function(i, msg){
					if(messageTo.length){
						showMessage(msg.message, msg.status, messageTo);
					}else{
						showMessage(msg.message, msg.status);
					}
				});
			}
			if (o.hasOwnProperty('alert') && o.alert) {
				if(messageTo.length){
					showMessage(o.alert, 'alert', messageTo);
				}else{
					showMessage(o.alert, 'warning');
				}
			}
			if (o.hasOwnProperty('error') && o.error) {
				if(messageTo.length){
					showMessage(o.error, 'danger', messageTo);
				}else{
					showMessage(o.error, 'danger');
				}
			}
		}
		// dodanie odpowiedzi do obiektu
		var $ob = {};
		if (f !== null) {
			if (f instanceof jQuery) {
				$ob = f;
			} else {
				$ob = $(f);
			}
			if (tf === 'val' || tf === 'value') {
				$ob.val(content);
			} else if (tf === 'checked') {
				$ob.attr('checked', content);
			} else if (tf === 'number' || tf === 'text' || tf === 'txt') {
				$ob.text(content);
			} else if (tf === 'html') {
				$ob.html(content);
			} else if (tf === 'show') {
				$ob.show(700);
			} else if (tf === 'hide') {
				$ob.hide(700);
			} else {
				showMessage('Data "typeFill" ' + tf + ' is unknown type!', 'error', 'main');
			}
		} else if($formFind !== null && $fillFind !== null) {
			liveLoadData($formFind, $fillFind);
		} else {
			return content;
		}
	});
}

// jako $inp - obiekt inputa z typem email
function emailTest($inp) {
	// sprawdzenie dla developera, nie dla usera
	if(!($inp instanceof jQuery)){
		// przykład tłumaczenia w JQuery bez parametrów, z plików json ".dic"
		var message = trans('email_object');
		console.log('emailTest', message);
		return 0;
	}
	
	// e-mail małymi literami bez spacji przed/po, dla poprawy wpisu usera
	// $m przed tym elementem pokaże się komunikat w oknie modalnym
	var email = $inp.val().toLowerCase().trim(),
		$g = $inp.parent().hasClass('input-group') ? $inp.parent() : false,
		$m = $g && $g.length && $g.siblings('label') !== undefined && $g.siblings('label').length ? $g.siblings('label') : 
			 ($inp.siblings('label') !== undefined && $inp.siblings('label').length ? $inp.siblings('label') : 
			 ($g && $g.length ? $g : $inp));
	 
	// usuwamy klasę error, jeśli jest z poprzedniego sprawdzania
	removeErrorFromInput($inp);
	
	// sprawdzenie, czy e-mail jest wymagany i czy pole zostało wypełnione jeśli jest
	if ($inp.prop('required') && !email.length) {
		addErrorToInput($inp);
		showMessage(trans('email_null'), 'danger', $m); // komunikat w oknie modalnym przed $m
		return false; // nie zamykamy okna modalnego jeśli brak maila
	}
	
	// sprawdzenie składni e-maila (w JQuery)
	if(!validateEmail(email)){
		addErrorToInput($inp);
		// przykład tłumaczenia z patametrami (JQuery dic)
		var message = trans('email_syntax', [
			{search: '%email%', replace: email}
		]);
		// komunikat w oknie modalnym, bo podajemy trzeci parametr (obiekt), przed jakim ma być komunikat
		showMessage(message, 'danger', $m);
		return false; // nie zamykamy okna modalnego przy złej składni
	}
	
	// zasadnicze sprawdzenie e-maila w php, wraz z domeną
	var action = 'https://' + readCookie('siteDomain') + '/test/email',
		data = { email: email },
		test = false;
	
	// uzycie async poawala zwrócić wynik do skryptu bezpośrednio z funkcji e-mailTest, bez setTimeOut
	$.ajax({
		url: action, 
		data: JSON.stringify(data), 
		dataType: 'json', 
		method: 'POST', 
		async: false
	}).done(function(result){ // sprawdzenie się udało
		// nie ma konieczności sprawdzania typu rezultatu w tym przypadku, ale jako przykład pozostawiam
		try {
			test = jQuery.parseJSON(result);
		} catch (error) {
			test = result;
		}
	}).fail(function(){ // sprawdzenie się nie udało (np. klient miał przerwę w internecie lub serwer zwariował)
		// przykład tłumaczenia trans (pliki asset/dic/(lang).json JQuery) z parametrami (tablica obiektów)
		var message = trans( 'email_fail', [
			{search: '%email%', replace: email}
		]);
		// komunikat nie w oknie modalnym, bo będzie zamknięte, tylko pod nagłówkiem (brak ostatniego parametru showMessage)
		showMessage( message, 'warning');
		test = true; // przepuścimy niepewny e-mail, żeby nie blokować akcji z powodu serwerowego błędu sprawdzania
	});
	
	if(test){ // e-mail OK (lub błąd sprawdzania)
		$inp.val(email); // jakby czasem były duze litery lub spacje
		return true; // zamknięcie okna modalnego
	}else{ // e-mail błędny - najprawdopodobniej domena nie jest uprawiona do wysyłania maili
		addErrorToInput($inp);
		// przykład tłumaczenia t przez php, z parametrami (1 obiekt, jak w twigu) i z wskazanym plikiem tłumaczenia
		var message = t('mail.test.bad', {'%email%': email}, 'adoption');
		// komunikat w oknie modalnym, bo go nie zamykamy (return 0) - podajemy obiekt $m, przed jakim pokaże się komunikat
		showMessage( message, 'danger', $m);
		return false;
	}
	
	// dodanie errora do inputa
	function addErrorToInput($inp){
		$inp.addClass('error');
		$inp.siblings('.input-group-prepend').addClass('error');
		$inp.siblings('.input-group-append').addClass('error');
	}
	
	// usunięcie errora z inputa
	function removeErrorFromInput($inp){
		if($inp.hasClass('error')){
			$inp.removeClass('error');
			$inp.siblings('.input-group-prepend').removeClass('error');
			$inp.siblings('.input-group-append').removeClass('error');
		}
	}
}
