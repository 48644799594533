export {trans, t, dict};
import {readCookie} from './cookie';

// Tłumaczenie przez trans(): słowniki w plikach assets/dic/(language).json
//		Tylko stałe komunikaty: 1 plik/język dla plików JQuery w aplikacji.
// Tłumaczenie przez t(): słowniki w standardowych plikach tłumaczeń Symfony
// dict(): odczyt niewrażliwych!! danych z plików assets/dic/(fileName).json

/**
 * trans
 * funkcja tłumacząca, korzystająca z plików assets/dic/(language).json
 * 
 * var array params = [{search: '%search_word%', replace: 'value'}, {search: '%search_word2%', replace: 'value2'}]
 * result string
 */ 
function trans(key = null, params = [], locale = null){

	var lang = locale === null ? readCookie('user_locale') : locale,
		dic = readDictionary(lang);

	if($.isEmptyObject(dic)){
		return '[' + lang + '] DICTIONARY NOT FOUND!';
	}

	if(key===null){
		return dic;
	}

	if(dic.hasOwnProperty(key)){
		return setParams(dic[key], params);
	}else{
		return trans( 'key_not_found', [ 
			{search: '%key%', replace: key}, 
			{search: '%file%', replace: lang} 
		]);
	}
}

// dla trans - odczyt jsona z pliku
function readDictionary(lang){
	var file = '/build/dic/'+lang+'.json',
		json = {};
	$.ajax({
		url:file, 
		async: false
	}).done(function(result){
		json = result;
	}).fail(function(){
		json = {};
	});
	return json;
}

// dla trans - podstawianie parametrów w wyszukanej frazie
function setParams(phrase, params){
	
	if ($.isArray(params) && !$.isEmptyObject(params)){
		
		$.each(params, function(i, item){
			phrase = phrase.replace(item.search, item.replace);
		});
		
	}
	
	return phrase;
	
}

// funkcja tłumacząca, korzystająca z mechanizmu tłumaczeń Symfony
// key = 'nazwa.klucza.w.translations'
// params = {"%zmienna1%": "wartość1", "%zmienna2%": "wartość2"}
// domain = 'adoptions' (nazwa pliku z translations bez rozszerzenia)
// locale = null (bez parametru, jeśli aktualnie włączony język)
function t( key, params={}, domain=null, locale=null ){
	
	if(!key){
		return null;
	}
	
	var data = { key: key, params: params, domain: domain, locale: locale },
		action = 'https://' + readCookie('siteDomain') + '/translation_for_jquery',
		o = '';

	$.ajax({
		url: action, 
		data: JSON.stringify(data), 
		dataType: 'json', 
		method: 'POST', 
		async: false
	}).done(function(result){
		try {
			o = jQuery.parseJSON(result);
		} catch (error) {
			o = result;
		}
	}).fail(function(){
		o = trans('trans_error');
	});
	
	return o;
}

// do odczytu wartości z klucza z innych plików json, niż tłumaczenia (nie testowałem, sprawdzić, poprawić)
// key = nazwa_klucza (jeśli null, zwraca całego jsona)
// fileName = nazwa_pliku (bez rozszerzenia, w folderze assets/dic/(file).json
function dict(key=null, fileName=null){
	
	var dic = readJsonFile(fileName);
	
	if($.isEmptyObject(dic)){
		return trans( 'dict_not_found', [
			{search: '%file%', replace: fileName}
		]);
	}
	
	if(key===null){
		return dic;
	}

	if(dic.hasOwnProperty(key)){
		
		return dic[key];
		
	}else{
		
		return trans( 'key_not_found', [ 
			{search: '%key%', replace: key}, 
			{search: '%file%', replace: fileName} 
		]);
	}
}

// dla dict
function readJsonFile(fileName){
	
	var file = '/build/dic/'+fileName+'.json',
		json = {};

	$.ajax({
		url: file, 
		async: false
	}).done(function(result){
		json = result;
	}).fail(function(){
		json = {};
	});

	return json;
}
